import { RemixBrowser } from "@remix-run/react";
import { hydrateRoot } from "react-dom/client";
import { ClientProvider } from "@mantine/remix";
import { emotionCache } from "./utils/emotionCache";

hydrateRoot(
  document,
  <ClientProvider emotionCache={emotionCache} >
    <RemixBrowser />
  </ClientProvider>,
);
